@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&family=Great+Vibes&display=swap');

html, body {
    height: 100%; /* Ensure body takes full height */
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-color: #121212;
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
    overflow-x: hidden; /* Prevent horizontal scroll */
}

/* Apply blur effect when loading */
.App.blur {
    filter: blur(5px);
    pointer-events: none;
}

.logo-text {
    font-family: 'Great Vibes', cursive;
    font-size: 3vw;
    color: #ffcc00;
    text-align: center;
    margin-bottom: 20px;
}

.selected-button {
    background-color: #5a647d !important;
    border-color: #5a647d !important;
    color: #fff !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
}

.mood-button .btn-outline-light,
.platform-button .btn-outline-light {
    background-color: transparent;
    border-color: #5a647d;
    color: #5a647d;
    opacity: 1;
    transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
    border-radius: 8px;
    font-size: 14px;
    padding: 10px;
    width: 140px;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mood-button .btn-outline-light:hover,
.platform-button .btn-outline-light:hover {
    background-color: #5a647d;
    color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.mood-button.red {
    background-color: #ff0000 !important;
    color: #fff !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

/* Loading overlay for the spinner */
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.loading-overlay .spinner-border {
    width: 3rem;
    height: 3rem;
    color: #ffffff;
}

@media (max-width: 768px) {
    .logo-text {
        font-size: 6vw; /* Adjusted for better visibility on tablets */
    }

    .mood-button .btn-outline-light,
    .platform-button .btn-outline-light {
        width: 120px;
        height: 120px;
        font-size: 13px; /* Slightly reduce font size for better fit */
    }
}

@media (max-width: 480px) {
    .logo-text {
        font-size: 8vw; /* Reduced to fit smaller mobile screens */
    }

    .mood-button .btn-outline-light,
    .platform-button .btn-outline-light {
        width: 100px;
        height: 100px;
        font-size: 11px; /* Further reduced for smaller screens */
    }

    .selected-button {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* Slightly less shadow for mobile */
    }
}